import { Metadata } from 'next';

const url = new URL(process.env.NEXT_PUBLIC_APP_URL || 'https://grapes.com');

export const metadata: Metadata = {
  title: 'Auth • Grapes',
  openGraph: {
    title: 'Auth • Grapes',
    description: 'Buying wine should be simple.',
    url,
    siteName: 'Grapes.com',
    locale: 'en_US',
    type: 'website'
  },
  metadataBase: url,
  alternates: {
    canonical: '/'
  },
  manifest: '/site.webmanifest'
};
