import { isAndroid, isIOS } from 'react-device-detect';
import { GRAPES_ANDROID_STORE_LINK, GRAPES_APP_LINK, GRAPES_IOS_APP_STORE_LINK } from '../../common';

interface Options {
  path?: string;
  params?: Record<string, string>;
  url?: string;
}

export const redirectToMobileApp = (opt: Options = {}) => {
  let link = GRAPES_APP_LINK + (opt.path ? opt.path : '');

  if (opt.params) {
    const params = Object.keys(opt.params)
      .map((key) => `${key}=${opt.params![key]}`)
      .join('&');
    link = link.concat(`?${params}`);
  }
  window.location.replace(link);

  const fallbackTimeout = 3000;

  if (isAndroid) {
    setTimeout(() => {
      window.location.replace(GRAPES_ANDROID_STORE_LINK);
    }, fallbackTimeout);
  } else if (isIOS) {
    setTimeout(() => {
      window.location.replace(GRAPES_IOS_APP_STORE_LINK);
    }, fallbackTimeout);
  }
};

export const redirectToSellerPortal = (opt: Options = {}) => {
  const url = new URL(opt.path ?? '/', opt.url);
  const params = new URLSearchParams();

  if (opt.params) {
    Object.keys(opt.params).forEach((key) => {
      params.append(key, opt.params![key]);
    });
  }

  window.location.replace(`${url.toString()}?${params.toString()}`);
};
