import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, FormHelperText } from '@mui/material';
import { useState } from 'react';
import { Control, useController } from 'react-hook-form';

interface InputProps {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  label?: string;
}

export const FormPasswordInput = (props: InputProps): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);
  const { field, fieldState } = useController({
    name: props.name,
    control: props.control
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const elementId = `id-password-${props.name}`;
  const hasError = !!fieldState.error;

  return (
    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
      <InputLabel htmlFor={elementId}>{props.label}</InputLabel>
      <OutlinedInput
        {...field}
        label={props.label}
        error={hasError}
        id={elementId}
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
      {hasError && <FormHelperText error>{fieldState.error?.message ?? 'Invalid password'}</FormHelperText>}
    </FormControl>
  );
};
