import { Metadata } from 'next';
import { metadata as defaultMetadata } from './default';

const url = new URL(process.env.NEXT_PUBLIC_GIFT_APP_URL || 'https://grapes.com');

export const metadata: Metadata = {
  ...defaultMetadata,
  title: `You've been gifted wine • Grapes`,
  openGraph: {
    ...defaultMetadata.openGraph,
    title: `You've been gifted wine • Grapes`,
    url
  },
  metadataBase: url
};
