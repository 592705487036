import styled from '@emotion/styled';
import Image from 'next/image';
import logoSrc from '../../../public/logo.png';

interface Props {
  title: string;
  message: React.ReactNode;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  text-align: center;
  padding: 1rem;
  padding-top: 3rem;

  @media (min-width: 768px) {
    justify-content: center;
    padding-top: 1rem;
  }
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 600;
  margin: 0;
`;

const Message = styled.p`
  font-size: 16px;
  font-weight: 400;
  margin: 0;
`;

const Logo = styled(Image)`
  margin-bottom: 20px;
`;

export const Result = (props: Props) => {
  return (
    <Container>
      <Logo src={logoSrc} alt="Grapes logo" />
      <Title>{props.title}</Title>
      <Message>{props.message}</Message>
    </Container>
  );
};
