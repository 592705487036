import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';

interface Props {
  fullPage?: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
`;

export const Loader = (props: Props) => {
  if (props.fullPage) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  return <CircularProgress />;
};
