import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { GetStaticProps } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Image from 'next/image';
import { useEffect } from 'react';
import { GRAPES_WEB_LINK, MetadataHead, appMetadata } from 'common';
import { redirectToMobileApp } from 'features/app-redirection';
import logoSrc from '../../public/logo.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  text-align: center;
  padding: 1rem;
  padding-top: 3rem;

  @media (min-width: 768px) {
    justify-content: center;
    padding-top: 1rem;
  }
`;

const Logo = styled(Image)`
  margin-bottom: 2rem;
`;

export default function IndexPage() {
  const { t } = useTranslation();

  useEffect(() => {
    redirectToMobileApp();
  }, []);

  return (
    <Container>
      <MetadataHead title="Home" metadata={appMetadata.default} />
      <Logo src={logoSrc} alt="Grapes logo" />
      <Button component="a" variant="outlined" href={GRAPES_WEB_LINK}>
        {t('not_found_page.button')}
      </Button>
    </Container>
  );
}

export const getStaticProps: GetStaticProps = async (context) => {
  const lang = context.locale ?? context.defaultLocale ?? 'en';
  return {
    props: {
      ...(await serverSideTranslations(lang, ['common']))
    }
  };
};
