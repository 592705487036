import { Metadata } from 'next';
import Head from 'next/head';
import { GRAPES_ANDROID_STORE_LINK, GRAPES_IOS_APP_STORE_LINK } from '../constants';
import { metadata as defaultMetaData } from './default';
import { metadata as giftMetadata } from './gift';

export const appMetadata = {
  default: defaultMetaData,
  gift: giftMetadata
};

interface Props {
  title: string;
  metadata: Metadata;
}

export const MetadataHead = (props: Props) => {
  const siteTitle = `${props.title} • Grapes`;
  return (
    <Head>
      <title>{siteTitle}</title>
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />

      <meta name="title" content={props.metadata.title?.toString()} />
      <meta name="description" content={props.metadata.openGraph?.description} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={props.metadata.openGraph?.url?.toString()} />
      <meta property="og:title" content={props.metadata.openGraph?.title?.toString()} />
      <meta property="og:description" content={props.metadata.openGraph?.description?.toString()} />
      <meta property="og:image" content="/og_image.jpg" />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={props.metadata.openGraph?.url?.toString()} />
      <meta property="twitter:title" content={props.metadata.openGraph?.title?.toString()} />
      <meta property="twitter:description" content={props.metadata.openGraph?.description?.toString()} />
      <meta property="twitter:image" content="/og_image.jpg" />

      <link rel="manifest" href={props.metadata.manifest?.toString()} />

      <meta property="al:ios:url" content={GRAPES_IOS_APP_STORE_LINK} />
      <meta property="al:ios:app_store_id" content="id1624638163" />
      <meta property="al:android:package" content="com.just.grapes.app" />
      <meta property="al:android:url" content={GRAPES_ANDROID_STORE_LINK} />
      <meta property="al:web:url" content="https://grapes.com" />
      <meta property="al:web:should_fallback" content="true" />
    </Head>
  );
};
